<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("fiscal_found.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("fiscal_found.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("fiscal_found.configuration") }}
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="showAction(1) && withPermissionName(permissions.CAT_FIN_ADD_01)"
              @click="changeAction(2)"
            >
              <i class="ti-plus mr-2"></i> {{ $t("global.create_source") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-paperclip mr-2"></i>{{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>

              <div v-show="showAction(1)">
                <TableComponent
                  ref="tableFund"
                  :headers="headers"
                  :isButton="true"
                  @excelTable="excelTable"
                  @updateRecord="edit"
                  :isUpdate="withPermissionName(permissions.CAT_FIN_EDIT_02)"
                />
              </div>

              <div v-if="showAction(2) || showAction(3)">
                <div class="card">
                  <div class="card-body p-1">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="doAction">
                        <div class="col-md-12 pb-0">
                          <h6>
                            <v-icon
                              class="pb-1"
                              v-text="'mdi-numeric-1-box-outline'"
                            />
                            {{ $t("fiscal_found.information_fiscal_found") }}
                          </h6>
                        </div>
                        <div class="col-md-12">
                          <validation-provider
                            v-slot="{ errors }"
                            name="name"
                            :rules="'required|max:100' + $UtilFront.RegExSpace()"
                          >
                            <v-text-field
                              v-model="model.name"
                              :counter="100"
                              maxlength="100"
                              oninput="this.value = this.value.toUpperCase()"
                              :error-messages="errors"
                              :label="$t('label.name')"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="acronym"
                            :rules="'required|max:50' + $UtilFront.RegExSpace()"
                          >
                            <v-text-field
                              v-model="model.key"
                              :counter="50"
                              maxlength="50"
                              :error-messages="errors"
                              class=""
                              oninput="this.value = this.value.toUpperCase()"
                              label="Siglas*"
                              required
                              autocomplete="off"
                            ></v-text-field>
                          </validation-provider>
                        </div>
                        <div class="col-md-12 text-right">
                          <button
                            class="btn btn-outline-success"
                            type="submit"
                            :disabled="invalid || sending"
                          >
                            <i class="feather icon-save mr-1"></i>
                            {{ $t("global.save_fiscalFound") }}
                          </button>
                        </div>
                      </form>
                    </validation-observer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Rightbar -->
  </div>
</template>

<script>
import { FISCAL_FUND } from "@/core/DataTableHeadersLocale";
import { FiscalFundRequest, ExcelRequest } from "@/core/request";
import TableComponent from "@/common/table/TableComponent";

export default {
  data() {
    return {
      title: "fiscal_found.fiscal_found",
      remmisionList: [],
      list: [],
      headers: FISCAL_FUND(),
      search: "",
      action: 1,
      sending: false,
      model: {
        name: "",
        key: "",
      },
      notifier: {
        notifying: false,
        type: "",
        message: "",
      },
      index: 0,
      permissions: this.$PermissionConstants,
    };
  },
  created() {
    this.listAll();
  },
  computed: {
    titleAction() {
      if (this.action == 1) {
        return "fiscal_found.fiscal_found";
      } else if (this.action == 2) {
        return "fiscal_found.register_fiscalfound";
      } else if (this.action == 3) {
        return "fiscal_found.edit_fiscalfound";
      }
      return "Listado";
    },
  },
  methods: {
    withPermissionName(numRol) {
      return this.$UtilFront.withPermissionName(numRol);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.action = action;
      } else {
        this.action = action;
      }
    },
    doAction() {
      if (this.action == 2) {
        this.add();
      } else if (this.action == 3) {
        this.update();
      }
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    listAll() {
      this.loading = this.$UtilFront.getLoadding();
      FiscalFundRequest.listAll()
        .then((res) => {
          let { data } = res.data.data;
          if (data.objects) {
            const list = data.objects.map((object) => {
              return object;
            });
            this.$refs.tableFund.reloadComponent(list);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    add() {
      this.sending = true;
      FiscalFundRequest.add({
        name: this.model.name,
        key: this.model.key,
        userBranchOfficeDTO: {
          idUser: this.$UtilFront.getDataUser().idUser,
          idBranchOffice: 0,
        },
      })
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success == true) {
            this.listAll();
            this.changeAction(1);
            this.alert("success", "Add_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error",error.message);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    update() {
      this.sending = true;
      FiscalFundRequest.update({
        idFiscalFund: this.model.idFiscalFund,
        name: this.model.name,
        key: this.model.key,
        userBranchOfficeDTO: {
          idUser: this.$UtilFront.getDataUser().idUser,
        },
      })
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success == true) {
            this.listAll();
            this.changeAction(1);
            this.alert("success", "Update_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    edit(object, index) {
      this.index = index;
      Object.assign(this.model, object);
      this.changeAction(3);
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportFiscalFound({});
    },
  },
  watch: {
    "$i18n.locale": function() {
      this.headers = FISCAL_FUND();
    },
  },
  components: {
    TableComponent,
  },
};
</script>

<style>
/* .theme--light.v-messages {
  color: rgb(255 0 0);
} */
</style>
